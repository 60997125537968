<template>
  <div class="wrap">
    <van-row type="flex">
      <van-col :span="24">
        <div class="header-title">
          <van-icon :name="require('../assets/images/rule-icon.png')" size="18"/>&nbsp;&nbsp;活动规则
        </div>
      </van-col>
      <van-col :span="24">
        <div class="desc">参与活动前，请仔细阅读以下活动规则，并确认已清楚了解活动参与方式、推广规则及奖品领取方式。</div>
        <div class="desc" style="margin-top: 10px;">活动规则</div>
        <div class="panel-wrap">
          <div class="title">
            一：购买流程：
          </div>
          <div class="desc">
            <ol>
              <li>购买时请选择您参与活动的4S店；</li>
              <li>输入您的车牌号码（若是新能源车辆请输入完整车牌号）和手机号（手机号将用于接收购买成功短信通知）；</li>
              <li>点击购买，支付完成，购买成功。</li>
<!--              <li>我们将在您购买成功后，给您发送一条活动短信，需凭短信到4S店参与活动，领取礼品。</li>-->
            </ol>
          </div>
          <div class="desc">我们将在您购买成功后，给您发送一条短信通知，需凭短信通知内容到您选择参与活动的4S店，领取礼品。</div>
        </div>

        <div class="panel-wrap">
          <div class="title">
            二：活动细则
          </div>
          <div class="desc">
            <ol>
              <li>在活动页面右下角点击生成分享，输入您的手机号后（请确保该手机号能正常接收短信，手机号是您领取奖品的唯一凭证，否则将不能正常接收奖励到账通知），可选择海报推广或链接推广方式。</li>
              <li>海报推广：点击生成的海报保存到本地相册，将海报转发给好友，好友通过微信扫描海报上的二维码，在扫描二维码生成的页面上购买成功后，您将收到一条好友购买成功的短信通知、并且我们会给您微信中发送一个红包奖励。</li>
              <li>链接推广：点击链接推广，生成您的专属推广页面，在页面的左上角有您微信昵称即推广链接生成成功。将此链接（点击微信页面右上角的转发图标）将链接转发给其他微信好友，好友在您转发的页面上购买成功后，您将收到一条好友购买成功的短信通知、并且我们会给您微信中发送一个红包奖励。</li>
              <li>分享人必须通过活动页面右下角“生成分享”按钮分享链接给其他用户，再通过微信右上角分享按钮转发，才可获得活动奖励。直接通过微信右上角分享按钮无法获得活动奖励。</li>
            </ol>
          </div>
        </div>

      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import {Col, Row, Image as VanImage, Icon} from "vant";
Vue.use(Row)
.use(Col)
.use(VanImage)
.use(Icon)
export default {
  name: "Rule"
}
</script>

<style scoped lang="sass">
.wrap
  margin-top: 24px
  padding: 0 15px 15px 15px
  box-sizing: border-box
  display: block
  width: 100vw
  max-height: 70vh
  overflow-y: scroll
  font-size: 14px
  line-height: 20px
  color: #666666
  .header-title
    display: flex
    justify-content: center
    width: 100%
    //background-color: #ff0000
    align-items: center
    font-size: 17px
    color: #333333
    line-height: 24px
    margin-bottom: 15px

  .desc
    display: flex
    padding-left: 10px
    box-sizing: inherit

  .panel-wrap
    display: flex
    flex-direction: column
    margin-top: 13px
    .title
      display: flex
      justify-content: flex-start
      align-items: inherit
      font-size: 14px
      line-height: 20px
      color: #333333
      &:before
        content: "•"
        color: #E16D42
        margin-right: 0.3em

      .panel-icon
        margin-right: 6px
    .desc
      margin-top: 5px
      ol
        counter-reset: section
        list-style-type: none
        li
          list-style-type: none
          display: inline-flex
          margin-bottom: 3px
          &:before
            content: counter(section) "."
            font-weight: 500
            //text-transform: uppercase
            padding-right: 3px
            counter-increment: section






</style>
