<template>
  <div class="custom-btn" @click="btnClickHandle">
    <div :class="{inner: true, disable: disable}">
      <van-loading type="spinner" v-if="loading" />
      <template v-else>{{ title }}</template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'vant';
Vue.use(Loading);

export default {
  name: "Vbtn",
  props: {
    title: {
      type: String,
      default: '确认'
    },
    disable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    btnClickHandle(){
      if(!this.disable && !this.loading){
        this.$emit('click')
      }

    },
  }
}
</script>

<style scoped lang="sass">
.custom-btn
  cursor: pointer
  margin: 15px 0
  background-color: #f7f7f7
  display: flex
  flex: 1
  border-radius: 25px
  .inner
    display: flex
    justify-content: center
    align-items: center
    height: 48px
    width: 100%
    background: linear-gradient(180deg, #FF9881 13.64%, #EF4230 100%)
    box-shadow: 2px 2px 4px rgba(48, 48, 48, 0.2)
    border-radius: 25px
    font-size: 18px
    color: #ffffff
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.25)
    &.disable
      opacity: 0.5
</style>
