import request from "../utils/axios"

export const signatureConfig = (url) => {
    return request({
        url: '/api/wechat/js/sdk/config',
        method: 'POST',
        data: {url}
    })
}

