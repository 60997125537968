import request from "../utils/axios"

// 活动详情
export const activityDetail = (id) => {
    return request({
        url: '/api/activity/detail',
        method: 'POST',
        data: {id}
    })
}

// 分享详情
export const shareDetail = (id) => {
    return request({
        url: '/api/activity/share/detail',
        method: 'POST',
        data: {id}
    })
}

export const share = (params)=>{
    const {activityId, shareId, phone} = params
    return request({
        url: '/api/activity/share/create',
        method: 'POST',
        data: {activityId, shareId, phone}
    })
}
export const poster = (params)=>{
    const {activityId, shareId, phone} = params
    return request({
        url: '/api/activity/share/poster/create',
        method: 'POST',
        data: {activityId, shareId, phone}
    })
}
export const store = (params)=>{
    const {activityId, shareId, phone, storeId, licensePlateNumber, newEnergy, code, giftName} = params
    return request({
        url: '/api/sale/order/create',
        method: 'POST',
        data: {activityId, shareId, phone, storeId, licensePlateNumber, newEnergy, code, giftName}
    })
}

// 订单支付结果查询
export const payCheck = (orderId)=>{
    return request({
        url: '/api/sale/order/pay/check',
        method: 'POST',
        data: {id: orderId}
    })
}

// 微信JSAPI支付统一下单
export const unifiedorder = (orderId)=>{

    return request({
        url: '/api/wxpay/jsapi/unifiedorder',
        method: 'POST',
        data: {orderId}
    })
}

export const getCode = (phone, activityType) => {
    return request({
        url: '/api/sms/code/send',
        method: 'POST',
        data:{phone, activityType}
    })
}
